import createModelContext from "../threejs/modelRenderer";
import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Snackbar, IconButton } from "@mui/material";
import ConfiguratorForm from "../Components/ConfigurationsSteps/ConfiguratorForm";
import ConfiguratorMenu from "../Components/Stepper/ConfiguratorMenu";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  fetchTextures,
  fetchPatterns,
  fetchBearerToken,
  fetchAllTextures,
} from "../helpers/DataRequests";
import { fetchPrice } from "../helpers/DataRequests";
import CircularProgress from "@mui/material/CircularProgress";
import ShareIcon from "@mui/icons-material/Share";

window.ModelContext = {
  create: createModelContext,
};

const Configurator = () => {
  const contentRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [modelFormData, setModelFormData] = useState({
    pattern: "tropical",
    patternName: "",
    type: "",
    sizeX: 890,
    sizeY: 677,
    sizeZ: 480,
    sizeL: 800,
    sizeH: 620,
    sizeP1: 400,
    sizeP2: 450,
    frontHeight: 660,
    bottom: false,
  });
  const [materialFormData, setMaterialFormData] = useState({
    material: "18",
    materialName: "Ivoire",
    alu: false,
    corten: false,
  });

  const [quotationData, setQuotationData] = useState({
    isNeeded: false,
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [price, setPrice] = useState(0);
  const [bearer, setBearer] = useState("");
  const [materialName, setMaterialName] = useState("Ivoire");
  const [patterns, setPatterns] = useState([]);
  const [textures, setTextures] = useState([]);
  const [allTextures, setAllTextures] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isButtonCartDisabled, setIsButtonCartDisabled] = useState(true);
  const [hidePrice, setHidePrice] = useState(false);
  const [hide3D, setHide3D] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getParamsFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    const modelData = {};
    const materialData = {};
  
    for (const [key, value] of params.entries()) {
      if (key in modelFormData) {
        modelData[key] = isNaN(value) ? value : Number(value);
      } else if (key in materialFormData) {
        materialData[key] = isNaN(value) ? value : Number(value);
      }
    }
  
    return { modelData, materialData };
  };
  
  useEffect(() => {
    const { modelData, materialData } = getParamsFromURL();

    if (Object.keys(modelData).length > 0) {
      setModelFormData((prev) => ({
        ...prev,
        ...modelData,
      }));
    }

    if (Object.keys(materialData).length > 0) {
      setMaterialFormData((prev) => ({
        ...prev,
        ...materialData,
      }));
    }
  }, []);

  useEffect(() => {
    const { update3DModel, update3DMaterial, cleanup, switchPauseScene } =
      window.ModelContext.create(contentRef.current);
    window.ModelContext.update3DModel = update3DModel;
    window.ModelContext.update3DMaterial = update3DMaterial;
    window.ModelContext.switchPauseScene = switchPauseScene;
    fetchAndUpdateToken();
    return () => {
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (!!bearer && bearer.length > 0) {
      fetchPatternsAndTextures();
    }
  }, [bearer]);

  const fetchPatternsAndTextures = async () => {
    setPatterns(await fetchPatterns(bearer));
    setTextures(await fetchTextures(bearer));
    setAllTextures(await fetchAllTextures(bearer));
  };

  const setupPrice = async (overrideX = null, overrideY = null, overrideZ = null) => {
    setPrice(
      await fetchPrice(
        bearer,
        overrideX ? Number(overrideX).toFixed(0) : modelFormData.sizeX.toFixed(0),
        overrideY ? Number(overrideY).toFixed(0) : modelFormData.frontHeight.toFixed(0),
        overrideZ ? Number(overrideZ).toFixed(0) :  modelFormData.sizeZ.toFixed(0),
        modelFormData.bottom,
        materialFormData.alu,
        materialFormData.corten
      )
    );
  };

  const fetchAndUpdateToken = async () => {
    setBearer(await fetchBearerToken());
  };

  useEffect(() => {
    let { pattern, sizeX, sizeY, sizeZ, bottom, type, frontHeight } = modelFormData;
    const { material } = materialFormData;
    const update3DModel = window.ModelContext.update3DModel;

    if (modelFormData.sizeX > 1350 || frontHeight > 1450 || modelFormData.sizeZ > 850) {
      setHide3D(true);
      return;
    }
    setHide3D(false);

    if (modelFormData.sizeX < 400) {
      sizeX = 400;
    }

    if (modelFormData.sizeY  < 500) {
      sizeY = 500
    }

    if (modelFormData.sizeZ < 350) {
      sizeZ = 350
    }

    setupPrice(sizeX.toFixed(0), frontHeight.toFixed(0), sizeZ.toFixed(0));

    update3DModel({
      size: {
        x: Number(sizeZ).toFixed(0),
        y: Number(sizeX).toFixed(0),
        z: Number(sizeY).toFixed(0),
      },
      bottom,
      material,
      pattern,
      type,
    });
  }, [modelFormData]);

  const applyCanvasOverlay = () => {
    const canvas = contentRef.current?.querySelector("canvas");  // Trouver le canvas Three.js
    if (canvas) {
      canvas.style.transition = "opacity 0.3s ease";  // Ajouter une transition fluide pour l'opacité
      canvas.style.opacity = "0.5";  // Appliquer l'opacité pour l'overlay
    }
  };

  const removeCanvasOverlay = () => {
    const canvas = contentRef.current?.querySelector("canvas");  // Trouver le canvas Three.js
    if (canvas) {
      canvas.style.opacity = "1";  // Rétablir l'opacité à 1 (canvas visible)
    }
  };

  useEffect(() => {
    const switchPauseScene = window.ModelContext.switchPauseScene;
    switchPauseScene(!hide3D);

    if (hide3D) {
      applyCanvasOverlay();  // Appliquer l'overlay sur le canvas
    } else {
      removeCanvasOverlay();  // Retirer l'overlay du canvas
    }
  }, [hide3D])

  useEffect(() => {
    const { material } = materialFormData;
    const update3DMaterial = window.ModelContext.update3DMaterial;

    if (modelFormData.sizeX > 1350 || modelFormData.sizeY > 1450 || modelFormData.sizeZ > 850) {
      return;
    }

    update3DMaterial(material).catch((err) => console.error("err", err));
  }, [materialFormData]);

  const handleModelInfosChange = (key, value) => {

    setModelFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleMaterialInfosChange = (key, value) => {
    const color = allTextures.find((c) => c.id === value);
    setMaterialName(color.name);

    let alu = false;
    let corten = false;

    if (color.materialType === "bois" || color.materialType === "métal") {
      alu = true;
    }

    if (color.name === "Corten") {
      corten = true;
      alu = false;
    }

    setMaterialFormData((prev) => ({
      ...prev,
      [key]: value,
      alu: alu,
      corten: corten,
    }));
  };
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const handleStepChange = (newStep) => {
    if (!modelFormData.type) {
      setIsButtonCartDisabled(true);
    } else {
      setIsButtonCartDisabled(false);
    }
    if (isXs && newStep === 2 && !modelFormData.type) {
      setSnackbarMessage(
        "Veuillez sélectionner un type de pose avant de passer à l'étape des dimensions."
      );
      setSnackbarOpen(true);
      setCurrentStep(1);
      return;
    } else if (newStep === 3 && !modelFormData.type) {
      setSnackbarMessage(
        "Veuillez sélectionner un type de pose avant de passer à l'étape des dimensions."
      );
      setSnackbarOpen(true);
      setCurrentStep(2);
      return;
    }
    setCurrentStep(newStep);
  };

  const handleQuotationInfosChange = (key, value) => {
    if(key === "noPrice"){
      setHidePrice(value)
    }
    setQuotationData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const generateShareableURL = () => {
    const baseUrl = window.location.origin + window.location.pathname;
    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(modelFormData)) {
      params.append(key, value);
    }

    for (const [key, value] of Object.entries(materialFormData)) {
      params.append(key, value);
    }

    const shareableURL = `${baseUrl}?${params.toString()}`;
    return shareableURL;
  };

  const handleShareCache = async () => {
    const url = generateShareableURL();
    try {
      await navigator.clipboard.writeText(url);
      setSnackbarMessage("Lien copié dans le presse-papiers !");
      setSnackbarOpen(true);
    } catch (err) {
      console.error("Échec de la copie : ", err);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleAddToCart = async () => {
    setIsLoading(true);
    const { sizeX, sizeY, sizeZ} = modelFormData;

    if (modelFormData.sizeX < 400) {
      sizeX = 400;
    }

    if (modelFormData.sizeY  < 500) {
      sizeY = 500
    }

    if (modelFormData.sizeZ < 350) {
      sizeZ = 350
    }

    try {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
      const bearer = await fetchBearerToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/validate-order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
          body: JSON.stringify({
            sizeX: sizeX,
            sizeY: sizeY,
            sizeZ: sizeZ,
            pattern: modelFormData.pattern,
            patternName: modelFormData.pattern,
            material: materialFormData.material,
            materialName: materialName,
            bottom: modelFormData.bottom,
            sizeL: modelFormData.sizeL,
            sizeH: modelFormData.sizeH,
            sizeP1: modelFormData.sizeP1,
            sizeP2: modelFormData.sizeP2,
            alu: materialFormData.alu,
            corten: materialFormData.corten,
            frontHeight: modelFormData.frontHeight
          }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        // Redirige l'utilisateur vers le panier WooCommerce
        window.location.href = data.cartUrl;
      } else {
        console.error("Erreur :", data.message);
      }
    } catch (err) {
      console.error("Erreur réseau :", err.message);
    }
  };

  setupPrice();

  return (
    <>
      {/* Configurator Menu - Sticky at top */}
      <ConfiguratorMenu
        price={price}
        currentStep={currentStep}
        onStepChange={handleStepChange}
        quotationDatas={quotationData}
        isButtonCartDisabled={isButtonCartDisabled}
        hidePrice={hidePrice}
        modelFormData={modelFormData}
        materialFormData={materialFormData}
        handleAddToCart={handleAddToCart}
      />

      {isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 2000,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          pt: { xs: 2, md: 8 },
          position: "relative"
        }}
      >
        {/* 3D Model Viewer */}
        <Box
          sx={{
            display: isMobile && currentStep === 1 ? "none" : "flex",
            flexDirection: "column",
            alignItems: "center",
            width: { xs: "100%", md: "50vw" },
            height: { xs: "32vh", sm: "unset" },
          }}
        >
          {/* Three.js */}
          <Box
            sx={{
              width: "100%",
              height: { xs: "40vh", sm: "50vh", md: "60vh" },
              backgroundColor: "#ffffff",
              position: { xs: "relative", md: "sticky" },
              bottom: { xs: "8vh", md: "unset" },
              top: { xs: "unset", md: -110 },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              ref={contentRef}
              sx={{
                width: "100%",
                height: { xs: "40vh", sm: "50vh", md: "60vh" },
                backgroundColor: "#ffffff",
                position: { md: "sticky" },
                top: { xs: 0, md: -110 },
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: { xs: 0, md: 0 }, // Move the icon to the top for mobile
                  left: 0,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0 16px",
                  zIndex: 3,
                }}
              >
                {/* made in france */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="img"
                    src="../images/Infos/france-coq.png"
                    alt="Fabriqué en France"
                    sx={{
                      width: { xs: 30, sm: 40, md: 50 },
                      height: { xs: 30, sm: 40, md: 50 },
                      mb: 1,
                    }}
                  />
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    sx={{
                      fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" },
                    }}
                  >
                    Fabriqué en France
                  </Typography>
                </Box>

                {/* bouton partager cache */}
                <Box>
                  <IconButton
                    onClick={handleShareCache}
                    sx={{
                      backgroundColor: "#efefef",
                      color: "#5CB1E2",
                      border: "1px solid transparent",
                      marginTop: { xs: "15px", md: 0 },
                      "&:hover": {
                        backgroundColor: "#efefef",
                        border: "1px solid #5CB1E2",
                      },
                    }}
                  >
                    <ShareIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            {/* box bouton et made in france */}
          </Box>

          {/*"Voir mon cache" */}
        </Box>

        {/* Configurator Form */}
        <Box
          sx={{
            width: { xs: "100%", md: "50vw" },
            padding: { xs: "10px", md: "0px" },
            paddingBottom: "30px",
          }}
        >
          <ConfiguratorForm
            step={currentStep}
            patterns={patterns}
            textures={textures}
            modelFormData={modelFormData}
            materialFormData={materialFormData}
            quotationData={quotationData}
            onModelFormChange={handleModelInfosChange}
            onMaterialFormChange={handleMaterialInfosChange}
            onQuotationChange={handleQuotationInfosChange}
            handleStepChange={handleStepChange}
          />
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          zIndex: 1200, // Ensure Snackbar is above all other UI components
          paddingBottom: { xs: "7vh", md: "5vh" }, // To avoid overlap with other UI elements like footers or fixed nav bars
        }}
      />
    </>
  );
};

export default Configurator;
